import React, { useState, useEffect, useRef } from "react";
import NarbarButton from "./NarbarButton";
import bannerImg from "../../assets/logo.png";
import { TiThMenu } from "react-icons/ti";

const Navbar = () => {
  const [activeButton, setActiveButton] = useState(""); // Trạng thái để lưu button được chọn
  const [menu, setMenu] = useState(false);
  const menuRef = useRef(null); // Ref để tham chiếu đến menu

  const handleButtonClick = (name) => {
    setActiveButton(name); // Cập nhật button được chọn
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenu(false); // Đóng menu khi nhấp ra ngoài
    }
  };

  useEffect(() => {
    // Thêm sự kiện mousedown để theo dõi nhấp chuột
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Loại bỏ sự kiện khi component bị hủy
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="border-2 rounded-[15px] shadow-xl mt-5 sticky top-0 z-50 bg-white py-3 min-sx:w-[360px] min-sm:w-[640px] min-md:w-[768px] min-lg:w-[1024px] min-xl:w-[1280px] min-2xl:w-[1536px] min-iphone-12:w-[390px] min-iphone-12-pro-max:w-[428px] min-pixel-5:w-[393px]">
        <div className="relative">
          <div className="w-full flex justify-between items-center">
            <div className="flex items-center">
              <img src={bannerImg} alt="Logo" className="max-md:w-[250px]" />
            </div>
            <div className="max-lg:hidden">
              <NarbarButton
                nameButton="Dịch Vụ"
                isActive={activeButton === "Dịch Vụ"}
                onClick={() => handleButtonClick("Dịch Vụ")}
              />
              <NarbarButton
                nameButton="Liên Hệ"
                isActive={activeButton === "Liên Hệ"}
                onClick={() => handleButtonClick("Liên Hệ")}
              />
              <NarbarButton
                nameButton="Dự Án"
                isActive={activeButton === "Dự Án"}
                onClick={() => handleButtonClick("Dự Án")}
              />
              <NarbarButton
                nameButton="Blog"
                isActive={activeButton === "Blog"}
                onClick={() => handleButtonClick("Blog")}
              />
            </div>
            <button
              className="hidden max-lg:block mr-[5%] text-[20px]"
              onClick={() => setMenu(!menu)}
            >
              <TiThMenu />
            </button>
          </div>
          {menu && (
            <div
              ref={menuRef}
              className="absolute top-16 right-0 bg-white py-3 rounded-xl shadow-xl w-[50%] flex flex-col"
            >
              <NarbarButton
                nameButton="Dịch Vụ"
                isActive={activeButton === "Dịch Vụ"}
                onClick={() => handleButtonClick("Dịch Vụ")}
              />
              <NarbarButton
                nameButton="Liên Hệ"
                isActive={activeButton === "Liên Hệ"}
                onClick={() => handleButtonClick("Liên Hệ")}
              />
              <NarbarButton
                nameButton="Dự Án"
                isActive={activeButton === "Dự Án"}
                onClick={() => handleButtonClick("Dự Án")}
              />
              <NarbarButton
                nameButton="Blog"
                isActive={activeButton === "Blog"}
                onClick={() => handleButtonClick("Blog")}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Navbar;
