import React from "react";
import ErpImage from "../../assets/erp_system.png";

const Section_Seven = () => {
  return (
    <div className="">
      <div className="font-calibri text-center text-[61px] max-sx:text-[35px] max-sm:text-[35px] md:text-[40px] lg:text-[61px] xl:text-[61px] 2xl:text-[61px] iphone-12:text-[40px] iphone-12-pro-max:text-[45px] pixel-5:text-[40px]">
        Hệ Thống ERP
      </div>
      <div className="bg-[#D9D9D9] flex flex-row items-center justify-between px-5 rounded-[30px] py-10 mt-10">
        <div className="text-[20px] font-semibold max-md:text-[16px] max-lg:text-[18px] max-sm:text-[14px] max-sx:text-[12px]">
          <div>Quản Lý Nguồn Nhân Lực (HRM)</div>
          <div className=" my-7 pr-28 ">
            <div className="border border-[#909090] "></div>
          </div>{" "}
          <div>Quản lý tài chính (FM)</div>
          <div className=" my-7 pr-28 ">
            <div className="border border-[#909090] "></div>
          </div>{" "}
          <div>Quản lý kho bãi (IM)</div>
          <div className=" my-7 pr-28 ">
            <div className="border border-[#909090] "></div>
          </div>{" "}
          <div>Quản lý khách hàng (CRM)</div>
          <div className=" my-7 pr-28 ">
            <div className="border border-[#909090] "></div>
          </div>{" "}
          <div>Quản lý nghiệp vụ (CM)</div>
        </div>

        <div className="text-[20px] font-semibold max-md:text-[16px] max-lg:text-[18px] max-sm:text-[14px] max-sx:text-[12px]">
          <div>Quản lý sản xuất (MRP)</div>
          <div className=" my-7 pr-28 ">
            <div className="border border-[#909090] "></div>
          </div>{" "}
          <div>Quản lý mua hàng (SCM)</div>
          <div className=" my-7 pr-28 ">
            <div className="border border-[#909090] "></div>
          </div>{" "}
          <div>Quản lý dự án (PM)</div>
          <div className=" my-7 pr-28 ">
            <div className="border border-[#909090] "></div>
          </div>{" "}
          <div>Quản lý tuân thủ (CM)</div>
          <div className=" my-7 pr-28 ">
            <div className="border border-[#909090] "></div>
          </div>{" "}
          <div>Tích hợp E-commerce (ECI)</div>
        </div>
        <div className="max-md:hidden">
          <img src={ErpImage} alt="Erp" />
        </div>
      </div>
    </div>
  );
};

export default Section_Seven;
