import React from "react";
import Loa from "../../assets/loa.png";

const Item = ({ logo, title, description }) => {
  return (
    <div className="w-[30%] rounded-[30px] border-2 relative text-center mt-[100px] py-20 max-md:w-[60%] max-sm:w-[90%] max-sx:w-[95%] max-lg:w-[60%]">
      <div className="absolute -top-11 text-center right-[40%] bg-[#FFDE95] w-[92px] h-[92px] rounded-full max-md:right-[40%] max-sm:right-[35%] max-sx:right-[35%] max-lg:right-[40%]">
        <p className="flex justify-center items-center h-full">
          <img src={logo} alt={logo} />
        </p>
      </div>
      <div className="flex flex-col items-center justify-center h-full ">
        <div className="text-[30px] font-calibri ">{title}</div>
        <p className="mt-5 text-[16px] font-medium  px-10 max-md:px-10 max-sm:px-5 max-sx:px-5 max-lg:px-10">{description}</p>
      </div>
    </div>
  );
};

export default Item;
