import React from "react";
import Item_Five from "./Item_Five";
import Laptop from "../../assets/laptop.png";

const Section_Five = () => {
  return (
    <div className="">
      <div className=" font-calibri text-center text-[61px] max-sx:text-[35px] max-sm:text-[35px] md:text-[40px] lg:text-[61px] xl:text-[61px] 2xl:text-[61px] iphone-12:text-[40px] iphone-12-pro-max:text-[45px] pixel-5:text-[40px]">
        Quá Trình Thực Hiện
      </div>

      <div className="mt-10 flex justify-center items-center w-full space-x-10">
        <div className="w-[50%] space-y-7 max-sx:w-full max-sm:w-[98] max-md:w-[98%] max-lg:w-[90%] xl:w-[80%]">
          <Item_Five number="01" title="Thu thập và đánh giá nhu cầu" />
          <Item_Five number="02" title="Phân tích và tối ưu hoá giải pháp" />
          <Item_Five
            number="03"
            title="Tham vấn và lên kế hoạch xây dựng giải pháp"
          />
          <Item_Five
            number="04"
            title="Triển khai và thu thập thông tin - Bảo dưỡng và phát triển hậu kỳ"
          />
        </div>
        <div className="w-[50%] max-md:hidden">
          <img src={Laptop} alt="Laptop" className="" />
        </div>
      </div>
    </div>
  );
};

export default Section_Five;
