import React from "react";

const Item_Five = ({ number, title }) => {
  return (
    <div className="border-2 py-5 text-center rounded-[25px] shadow-md relative">
      <div className="text-[20px] px-36 max-sx:px-16 max-sm:px-16 max-md:px-20 max-lg:px-20 xl:px-20">{title}</div>
      <div className="absolute -top-5 w-[65px] h-[65px] bg-[#FFDE95] rounded-full text-[30px]">
        <p className="flex items-center justify-center h-full text-[#555555] font-bold">
          {number}
        </p>
      </div>
    </div>
  );
};

export default Item_Five;
