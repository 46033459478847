import "./App.scss";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/material";
import Navbar from "./components/Navbar/Navbar";
import Section_One from "./components/Section_One/Section_One";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { X } from "@mui/icons-material";
import Section_Two from "./components/Section_Two/Section_Two";
import Section_Three from "./components/Section_Three/Section_Three";
import Section_Four from "./components/Section_Four/Section_Four";
import Section_Five from "./components/Section_Five/Section_Five";
import Section_Six from "./components/Section_Six/Section_Six";
import Section_Seven from "./components/Section_Seven/Section_Seven";
import Section_Eight from "./components/Section_Eight/Section_Eight";
import Footer from "./components/Footer/Footer";

function App() {
  // Sử dụng Intersection Observer để theo dõi phần tử trong viewport
  const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref3, inView3] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref4, inView4] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref5, inView5] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref6, inView6] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref7, inView7] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref8, inView8] = useInView({ triggerOnce: true, threshold: 0.1 });
  return (
    <div className="App">
      <Container >
        <Grid container spacing={6}>
          <Grid size={{ xs: 12, md: 12, sm: 12 }}>
            <Navbar />

            {/* Section 1 với hiệu ứng mờ đến rõ */}
            <motion.div
              ref={ref1} // Theo dõi phần tử khi cuộn vào khung nhìn
              initial={{ opacity: 0, x: 100 }} // Bắt đầu từ mờ và bị đẩy xuống dưới
              animate={inView1 ? { opacity: 1, x: 0 } : {}} // Khi inView, chuyển thành rõ và trở về vị trí gốc
              transition={{ duration: 2 }}
            >
              <Grid sx={{ marginTop: "100px" }}>
                <Section_One />
              </Grid>
            </motion.div>

            {/* Section 2 với hiệu ứng mờ đến rõ */}
            <motion.div
              ref={ref2}
              initial={{ opacity: 0, y: 200 }} // Bắt đầu từ mờ và bị đẩy xuống dưới
              animate={inView2 ? { opacity: 1, y: 0 } : {}} // Khi inView, chuyển thành rõ và trở về vị trí gốc
              transition={{ duration: 1 }}
            >
              <Grid sx={{ marginTop: "150px" }}>
                <Section_Two />
              </Grid>
            </motion.div>

            <motion.div
              ref={ref3}
              initial={{ opacity: 0, y: 200 }} // Bắt đầu từ mờ và bị đẩy xuống dưới
              animate={inView3 ? { opacity: 1, y: 0 } : {}} // Khi inView, chuyển thành rõ và trở về vị trí gốc
              transition={{ duration: 1 }}
            >
              <Grid sx={{ marginTop: "150px" }}>
                <Section_Three />
              </Grid>
            </motion.div>

            <motion.div
              ref={ref4}
              initial={{ opacity: 0, y: 200 }} // Bắt đầu từ mờ và bị đẩy xuống dưới
              animate={inView4 ? { opacity: 1, y: 0 } : {}} // Khi inView, chuyển thành rõ và trở về vị trí gốc
              transition={{ duration: 2 }}
            >
              <Grid sx={{ marginTop: "150px" }}>
                <Section_Four />
              </Grid>
            </motion.div>

            <motion.div
              ref={ref5}
              initial={{ opacity: 0, y: 200 }} // Bắt đầu từ mờ và bị đẩy xuống dưới
              animate={inView5 ? { opacity: 1, y: 0 } : {}} // Khi inView, chuyển thành rõ và trở về vị trí gốc
              transition={{ duration: 1 }}
            >
              <Grid sx={{ marginTop: "150px" }}>
                <Section_Five />
              </Grid>
            </motion.div>

            <motion.div
              ref={ref6}
              initial={{ opacity: 0, y: 200 }} // Bắt đầu từ mờ và bị đẩy xuống dưới
              animate={inView6 ? { opacity: 1, y: 0 } : {}} // Khi inView, chuyển thành rõ và trở về vị trí gốc
              transition={{ duration: 1 }}
            >
              <Grid sx={{ marginTop: "150px" }}>
                <Section_Six />
              </Grid>
            </motion.div>

            <motion.div
              ref={ref7}
              initial={{ opacity: 0, y: 200 }} // Bắt đầu từ mờ và bị đẩy xuống dưới
              animate={inView7 ? { opacity: 1, y: 0 } : {}} // Khi inView, chuyển thành rõ và trở về vị trí gốc
              transition={{ duration: 1 }}
            >
              <Grid sx={{ marginTop: "150px" }}>
                <Section_Seven />
              </Grid>
            </motion.div>

            <motion.div
              ref={ref8}
              initial={{ opacity: 0, y: 200 }} // Bắt đầu từ mờ và bị đẩy xuống dưới
              animate={inView8 ? { opacity: 1, y: 0 } : {}} // Khi inView, chuyển thành rõ và trở về vị trí gốc
              transition={{ duration: 1 }}
            >
              <Grid sx={{ marginTop: "150px" }}>
                <Section_Eight />
              </Grid>
            </motion.div>
          </Grid>
        </Grid>
      </Container>
      <div className="mt-[300px]">
        <Footer />
      </div>
    </div>
  );
}

export default App;
