import React from "react";
import solutionImg from "../../assets/solution.png";
import { Button } from "@mui/material";

const Section_Three = () => {
  return (
    <div className="bg-[#D9D9D9] flex items-center justify-center w-full py-10 rounded-xl space-x-20">
      <div className="w-[40%] max-md:w-[90%]">
        <div className="font-calibri text-[61px] max-sx:text-[35px] max-sm:text-[35px] md:text-[40px] lg:text-[61px] xl:text-[61px] 2xl:text-[61px] iphone-12:text-[40px] iphone-12-pro-max:text-[45px] pixel-5:text-[40px]">
          Giải Pháp Của <br className="hidden" /> Chúng Tôi
        </div>
        <div className="text-[16px] w-[80%] mt-5">
          Golden Cloud Technology luôn cống hiến hết mình cho ra những sản phẩm
          với tiêu chí tốt và chi phí phải chăng.
        </div>
        <div className="mt-7">
          <Button
            sx={{
              backgroundColor: "#FFDE95",
              color: "black",
              fontWeight: "bold",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            Xem Thêm &rarr;
          </Button>
        </div>
      </div>
      <div className="flex items-center w-[40%] max-md:hidden">
        <img src={solutionImg} alt="Logo" className="" />
      </div>
    </div>
  );
};

export default Section_Three;
