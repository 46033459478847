import React from "react";
import GD1 from "../../assets/GD1.png";
import GD2 from "../../assets/GD2.png";
import FB from "../../assets/facebook.png";
import IST from "../../assets/insta.png";
import MAIL from "../../assets/mail.png";

const Footer = () => {
  return (
    <div className="bg-[#D9D9D9] ">
      <div className="w-full flex flex-col justify-center items-center py-20">
        <img src={GD1} className="w-[204px] h-[72px] " />
        <img src={GD2} className="w-[353px] h-[61px]" />

        <div className=" py-10">
          <div className="border border-[#909090] "></div>
        </div>
        <div className="flex items-center justify-between w-[1125px] max-lg:w-screen max-lg:items-start max-lg:px-10 max-lg:flex-col max-lg:space-y-10">
          <div className="w-[25%] text-[16px] space-y-3">
            <p className="text-[18px] font-bold">Liên Hệ</p>
            <p className="max-lg:w-[230px]">SĐT: 0973082002</p>
            <p className="max-lg:w-[300px]">Gmail: trinhquangduy3442@gmail.com</p>
          </div>
          <div className="w-[45%] text-center text-[16px] space-y-3 max-lg:text-start max-lg:w-[25%]">
            <p className="text-[18px] font-bold">Địa Chỉ</p>
            <p className="max-lg:w-[230px]">
              Tòa S7.03 Vinhomes Grand Park,
              <br /> Quận 9, Tp Thủ Đức.
            </p>
          </div>
          <div className="w-[25%] flex text-[16px] space-x-3">
            <img src={FB} />
            <img src={IST} />
            <img src={MAIL} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
