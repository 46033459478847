import React from "react";
import Ecomerce from "../../assets/ecommerce.png";

const Section_Eight = () => {
  return (
    <div>
      <div className=" font-calibri text-center text-[61px] max-sx:text-[35px] max-sm:text-[35px] md:text-[40px] lg:text-[61px] xl:text-[61px] 2xl:text-[61px] iphone-12:text-[40px] iphone-12-pro-max:text-[45px] pixel-5:text-[40px]">
        E-commerce
      </div>
      <div className="hidden max-md:block mt-5">
          <img src={Ecomerce} alt="Ecomerce" />
        </div>
      <div className=" mt-10 flex space-x-32 items-center justify-center max-md:space-x-0">
        <div className="max-md:hidden">
          <img src={Ecomerce} alt="Ecomerce" />
        </div>
        <div className="text-[20px] font-semibold">
          <div>Quản Lý Nguồn Nhân Lực (HRM)</div>
          <div className=" my-7 pr-28 ">
            <div className="border border-[#909090] "></div>
          </div>{" "}
          <div>Quản lý tài chính (FM)</div>
          <div className=" my-7 pr-28 ">
            <div className="border border-[#909090] "></div>
          </div>{" "}
          <div>Quản lý kho bãi (IM)</div>
          <div className=" my-7 pr-28 ">
            <div className="border border-[#909090] "></div>
          </div>{" "}
          <div>Quản lý khách hàng (CRM)</div>
        </div>
      </div>
    </div>
  );
};

export default Section_Eight;
