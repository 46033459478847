import React from "react";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import bannerImg from "../../assets/banner.png";

function Section_One() {
  return (
    <div className="flex flex-col space-y-5 items-center justify-center">
      <div className=" font-calibri text-[61px] max-sx:text-[35px] max-sm:text-[35px] md:text-[40px] lg:text-[61px] xl:text-[61px] 2xl:text-[61px] iphone-12:text-[40px] iphone-12-pro-max:text-[45px] pixel-5:text-[40px]">
        Chúng Tôi Cung Cấp <br /> Tính Năng Mạnh Mẽ
      </div>
      <div className="text-center text-[18px]">
        Giúp doanh nghiệp giải quyết các bài toán khó khăn trong quản lí và vận
        hành
      </div>
      <div>
        <img src={bannerImg} alt="banner" />
      </div>
    </div>
  );
}

export default Section_One;
