import React from "react";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";
import bannerImg from "../../assets/about.png";

const Section_Two = () => {
  return (
    <div className="flex justify-center  px-32 max-sx:px-0 max-sm:px-0 max-md:px-0 max-lg:px-10 max-xl:px-14">
      <div className="flex items-center w-[40%] max-md:hidden">
        <img src={bannerImg} alt="Logo" className="h-[637] w-[481px]" />
      </div>
      <div className="w-[45%] flex flex-col justify-center -mt-10 pl-10 max-lg:w-full max-lg:pl-0">
        <div className="font-calibri text-[61px] max-sx:text-[35px] max-sm:text-[35px] md:text-[40px] lg:text-[61px] xl:text-[61px] 2xl:text-[61px] iphone-12:text-[40px] iphone-12-pro-max:text-[45px] pixel-5:text-[40px]">
          Về Chúng Tôi
        </div>
        <div className="mt-5 text-[18px]">
          Golden Cloud Technology chúng tôi nâng cao hiệu quả kinh doanh bằng
          các giải pháp phần mềm toàn diện. Từ hệ thống CRM và HRM trực quan đến
          tích hợp MRP và WMS mạnh mẽ, chúng tôi điều chỉnh mọi giải pháp để đáp
          ứng nhu cầu cụ thể của bạn. Chuyên môn của chúng tôi đảm bảo sản phẩm
          của chúng tôi có thiết kế liền mạch, thân thiện với người dùng, giúp
          tối ưu hoá hoạt động của bạn. Trải nghiệm sự đổi mới, dịch vụ tuỳ
          chỉnh và kết quả ấn tượng khi sử dụng dịch vụ của Golden Cloud
          Technology.
        </div>
        <div className="mt-5 ">
          <Button
            sx={{
              backgroundColor: "#FFDE95",
              color: "black",
              fontWeight: "bold",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            Xem Thêm &rarr;
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Section_Two;
