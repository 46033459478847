import React from "react";
import Item from "./Item";
import Loa from "../../assets/loa.png";
import Erp from "../../assets/erp.png";
import Shopping from "../../assets/shopping.png";

const Section_Four = () => {
  return (
    <div>
      <div className="font-calibri text-center text-[61px] max-sx:text-[35px] max-sm:text-[35px] md:text-[40px] lg:text-[61px] xl:text-[61px] 2xl:text-[61px] iphone-12:text-[40px] iphone-12-pro-max:text-[45px] pixel-5:text-[40px]">
        Dịch Vụ Của
        <br /> Chúng Tôi
      </div>
      <div className="flex justify-between w-full max-lg:flex-col max-lg:justify-center max-lg:items-center ">
        <Item
          logo={Loa}
          title="Website Quảng Bá"
          description="Trang Web quảng bá hay còn gọi là Landing Page đóng vai trò là một tấm
          danh thiếp của doanh nghiệp trên môi trường số. Một trang quảng bá đẹp
          và hiện đại góp phần xây dựng niềm tin và tạo ấn tượng với khách hàng."
        />
        <Item
          logo={Erp}
          title="Hệ Thống ERP"
          description="Quản lý các doanh nghiệp trực quan hiệu quả và mọi lúc mọi nơi luôn là mong muốn của nhà quản trị. Giải pháp hệ thống quản trị tài nguyên doanh nghiệp (Enterprise Resources Planning - ERP) của chúng tôi được xây dựng và phát triển dành riêng cho nhu cầu của từng doanh nghiệp trong khi vẫn đảm bảo thực hiện những tiêu chí trên."
        />
        <Item
          logo={Shopping}
          title="Ứng Dụng E-commerce"
          description="Trang Web quảng bá hay còn gọi là Landing Page đóng vai trò là một tấm danh thiếp của doanh nghiệp trên môi trường số. Một trang quảng bá đẹp và hiện đại góp phần xây dựng niềm tin và tạo ấn tượng với khách hàng."
        />
      </div>
    </div>
  );
};

export default Section_Four;
