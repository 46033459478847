import React from "react";
import Landing1 from "../../assets/landing_1.png";
import Landing2 from "../../assets/landing_2.png";

const Section_Six = () => {
  return (
    <div className="">
      <div className=" font-calibri text-center text-[61px] max-sx:text-[35px] max-sm:text-[35px] md:text-[40px] lg:text-[61px] xl:text-[61px] 2xl:text-[61px] iphone-12:text-[40px] iphone-12-pro-max:text-[45px] pixel-5:text-[40px]">
        Landing Page
      </div>

      <div className="flex flex-row w-full items-center justify-between mt-16">
        <div className="max-md:hidden">
          <img src={Landing1} alt="Landing1" />
        </div>
        <div className="text-[21px] text-center w-full">
          <div>Thiết Kế Tiêu Chuẩn</div>
          <div className=" my-8 px-20 ">
            <div className=" flex justify-center">
              <div className="border border-[#909090] w-[40%]"></div>
            </div>
          </div>
          <div>Marketing và SEO</div>
          <div className=" my-8 px-20 ">
            <div className=" flex justify-center">
              <div className="border border-[#909090] w-[40%]"></div>
            </div>
          </div>
          <div>Thu thập thông tin khách hàng</div>
        </div>
        <div className="max-md:hidden">
          <img src={Landing2} alt="Landing2" />
        </div>
      </div>
    </div>
  );
};

export default Section_Six;
