import { Button } from "@mui/material";
import React from "react";

const NarbarButton = ({ nameButton, isActive, onClick }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        color: "black", // Thay đổi màu khi active
        backgroundColor: isActive ? "#FFDE95" : "transparent", // Thay đổi nền khi active
        marginLeft: "15px",
        marginRight: "15px",
        fontWeight: "bold",
        fontSize: "16px",
        paddingRight: "35px",
        paddingLeft: "35px",
        borderRadius: "13px", // Tăng độ bo tròn
        "&:hover": {
          backgroundColor: isActive ? "#FFDE95" : "#f0f0f0", // Màu khi hover
        },
      }}
    >
      {nameButton}
    </Button>
  );
};

export default NarbarButton;
